import { render, staticRenderFns } from "./learnTalentDetail.vue?vue&type=template&id=06b3b9eb&scoped=true&"
import script from "./learnTalentDetail.vue?vue&type=script&lang=js&"
export * from "./learnTalentDetail.vue?vue&type=script&lang=js&"
import style0 from "./learnTalentDetail.vue?vue&type=style&index=0&id=06b3b9eb&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06b3b9eb",
  null
  
)

export default component.exports